:root {
    font-size: 16px;
    --main-body-color: #27453A;
    --container-body-color: #EFF1EB;
    --primary-color-one: #F4E9D5;
    --primary-color-two: #B47E2E;
    --primary-color-three: #D2AB6F;
    --primary-color-four: #657966;
    --primary-color-five: #AD6347;

    --primary-color-fav: rgb(32, 51, 53) !important;

    --btn-color-active: #47cf73;
    --btn-color-one: #AD6347;
    --btn-color-two: #4A6D5E;
}

/* BODY TAG STYLE */
/* BODY TAG START*/
/* *** */
/* *** */
/* *** */

body {
    background: var(--main-body-color) !important;
    min-height: 100%;
    height:100vh;
}

.container-fluid {
    margin: 0 !important;
    padding: 0 !important;
}


.bg-qorum { background: var(--primary-color-fav) !important}
.bg-qorum-active { background:  var(--btn-color-active) !important}
.display-5 { font-size:2rem; font-weight:300; line-height:1.2 }
.display-6 { font-size:1.25rem; font-weight:300; line-height:1.2 }
.btn-qorum { background: var(--primary-color-four) !important; color: white !important}
.btn-qorum-fav { background: var(--primary-color-fav) !important; color: white}
.btn-qorum-fav:hover { background: var(--btn-color-active) !important; color: white;}

#navbar_custom {
    background: rgb(32, 51, 53) !important;
    color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

#navbar_custom .nav-item {
    color: white
}

#navbar_custom .nav-item div > span {
    font-size: 10px;
    color: white
}

#nav-cse {
    background : var(--container-body-color);
    margin: 5px;
    padding: 0 1rem;
    border-radius: 5px;
}

#nav-cse .nav-item:nth-child(2n+0) {
    border-right: 1px solid black;
    border-left: 1px solid black;
}

#nav-cse .nav-item:last-child {
    border-right: none;
}

#nav-cse .nav-item > .nav-link:not(.active), #tablists .nav-link {
    font-weight: bold;
    text-decoration: none;
    color: white;
}

#nav-cse a.active, #meeetingtablists a.active, #tablists a.active {
    background-color: var(--btn-color-active);
    font-weight: bold;
    color: black !important;
    border: 1px solid black;
    border-radius: 5px;
    margin: 0 1rem ;
}

#nav-cse a:not(.active), #tablists a:not(.active) {
    background-color: var(--btn-color-two);
    border: 1px solid black;
    border-radius: 5px;
    margin: 0 1rem ;
}

#nav-cse a > span {
    padding: 0 .5rem;
}

#nav-cse a:hover {
    background-color: var(--btn-color-active);
    font-weight: bold;
    border: 1px solid black;
    border-radius: 5px;
    margin: 0 1rem ;
}

.href-custom {
    background: none !important;
    text-decoration: none !important;
    border: 0 !important;
    color: var(--primary-color-four) !important;
    font-weight: bold !important;
}

.href-custom:hover {
    text-decoration: none !important;
    border: 0 !important;
    color: var(--btn-color-active) !important;
    transform: scale(1.025);
    transition: all .3s ease;
}

#container-custom .btn-custom {
    background-color: unset;
    border: 1px solid var(--primary-color-four);
    color: black;
    margin: 0 .25rem;
}

#container-custom .btn-custom:active {
    background-color: unset;
    border: 1px solid var(--primary-color-four);
    color: black;
    margin: 0 .25rem;
}

#container-custom .btn-custom-banner {
    background-color: var(--container-body-color);
    border: 1px solid black;
    color: black !important;
    margin: 0 .25rem;
    transition: all .3s ease;
}

#container-custom .btn-custom-banner:hover {
    background-color: var(--btn-color-active);
    border: 1px solid black;
    color: black;
    margin: 0 .25rem;
}

#fixed-top-menu {
    position: fixed;
    width: 100%;
    z-index: 100;
}

#pages {
    position: relative;
    padding-top: 7.3em;
}

#container-custom {
    min-height: 64.25vh !important;
    background-color:  var(--container-body-color);
    margin: 0;
    margin-bottom: 2rem;
    padding: 1.5rem;
    height: 100% !important;
    min-width: 100%;
    width: min-content
}

#container-custom .paginationButton
{
    background-color: unset;
    border: 1px solid var(--primary-color-four);
    color: black !important;
    margin: 0 2px;
    padding: 2px 5px;
}

#container-custom .btn-custom:hover,
#container-custom .tableshowchange button:hover
{
    background-color: var(--primary-color-four);
    color: white !important;
    transform: scale(1.1);
    transition: all .3s ease;
}

.table-header-custom, .banner-custom {
    background-color:  rgb(32, 51, 53) ;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    padding: 1rem 0;
    margin-left: 0;
    border-radius: 0.5rem;
}

/* *** */
/* *** */
/* *** */
/* BODY TAG END*/


/* CONTAINER STYLE */
/* CONTAINER STYLE START*/
/* *** */
/* *** */
/* *** */

/* #divider-vetical {
    margin: 1rem 5px 1rem;
    border: 1px solid rgba(60, 64, 67, 0.3);
    background: rgba(60, 64, 67, 0.3);
} */

#container-custom .card {
    /* box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    /* box-shadow: 0 1px 12px 0 rgba(129, 137, 169, .2); */
    border-radius: 0.25rem;
    margin: 1rem .5rem !important;
    /* margin-bottom: 3em !important; */

    transition: all .1s ease;
    border: 2px solid var(--container-body-color);
}

/* #container-custom .card > .card-header {
    background-color: chocolate !important;
    margin: -20px 15px !important;
    border-radius: 3px;
     padding: 15px;
}*/
#container-custom .card > .card-header {
    /* background-color:  rgb(32, 51, 53) !important; */
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    /* color: white !important; */
} 
 
#container-custom .card > .card-footer {
    /* margin-top: .5rem !important; */
    padding-bottom: 1rem !important;
} 

#container-custom .card:hover {
   border: 2px solid var(--primary-color-four);
}

#container-custom .list-group-flush > li:hover {
    background-color: var(--btn-color-two);
    color: white
}

#container-custom .hline-custom {
    border: 2px solid var(--main-body-color);
    border-radius: 5px
}

#container-custom .hline-custom-separator {
    border: 1px solid var(--main-body-color);
    border-radius: 5px
}

#gest-space {
    position: relative;
    height: 75vh;
}

#guest-image {
    /* position: absolute; */
    top: 20%;
}

.page-item:first-child .page-link, .page-item:last-child .page-link, .page-item {
    border-radius: 5px !important;
    margin: 0 1px !important;
    color: black !important;
}

.page-item .page-link {
    color:black !important
}

.page-item.active .page-link {
    background-color: rgb(32, 51, 53) !important;
    border-radius: 5px !important;
    border-color:  white !important;
    color: white !important;
}

/* *** */
/* *** */
/* *** */
/* CONTAINER STYLE END*/

/* FOOTER STYLE */
/* FOOTER STYLE START*/
/* *** */
/* *** */
/* *** */
.footer-custom {
    background: rgb(32, 51, 53) !important;
    width:  100% !important;
    height:  3.5rem !important;
    padding-top: 1rem;
    left: 0 !important;
}

.footer-custom .icon-color {
    color: white;
}


/* *** */
/* *** */
/* *** */
/* FOOTER STYLE END*/

.progress .circle {
    display: inline-block;
    background: unset;
    width: 80px; height: 40px;
    border-radius: 10px;
    border: 1px solid black;
}

.progress .bar {
    background-color: black;
    align-self: center;
    /* position: relative; */
    width: 40px;
    height: 3px;
    top: 20px;
    border-left: none;
    border-right: none;
    border-radius: 0;
}

.progress .circle.done {
  background:#47cf73 !important;
  color: white !important
}

.progress .circle.notdone {
    background: brown !important;
    color: white !important
}

  /* Form Progress */
.progress_custom {
  /* margin: 1rem auto; */
  text-align: center;
}

.progress_custom .circle_custom,
.progress_custom .bar_custom {
    margin: 1.5rem 0; 
    display: inline-block;
    background: #fff;
    width: 3.5rem; height: 3.5rem;
    border-radius: 3.5rem;
    border: 1px solid #d5d5da;
}

.progress_custom .bar_custom {
    position: relative;
    width: 200px;
    height: 8px;
    top: 24px;
    margin-left: 0;
    margin-right: 0;
    border-left: none;
    border-right: none;
    border-radius: 0;
}

.progress_custom .title {
    position: absolute;
    width: 5rem;
    margin-left: -1rem;
    font-weight: bold;
    font-size: 0.75rem;
}

.progress_custom .circle_custom .label {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    border-radius: 3rem;
    margin-top: 3px;
    color: #b5b5ba;
    font-size: 1.25rem;
}

.progress_custom .circle_custom .date {
    font-weight: bold;
    font-size: 0.75rem;
    color: #b5b5ba;
    line-height: 2rem;
    margin-left: -5px;
}

/* Done / Active */
.progress_custom .bar_custom.active {
    background: linear-gradient(to right, #8bc435 20%, #FFF 80%);
}

.progress_custom .bar_custom.done {
    background: #8bc435;
}

.progress_custom .bar_custom.notdone {
    background: crimson;
}

.progress_custom .circle_custom.notdone .label {
    color: #FFF;
    background: crimson;
    box-shadow: inset 0 0 2px rgba(0,0,0,.2);
}

.progress_custom .circle_custom.notdone .date {
    position: absolute;
    width: 7rem;
    line-height: 1rem;
    margin-top: 3.75rem;
    margin-left: -5rem;
}

.progress_custom .circle_custom.done .label {
    color: #FFF;
    background: #8bc435;
    box-shadow: inset 0 0 2px rgba(0,0,0,.2);
}

.progress_custom .circle_custom.done .date {
    position: absolute;
    width: 7rem;
    color: #444;
    line-height: 1rem;
    margin-top: 3.75rem;
    margin-left: -5rem;
}

.progress_custom .circle_custom.active .label {
    color: #FFF;
    margin-right: 4px;
    background: #0c95be;
    box-shadow: inset 0 0 2px rgba(0,0,0,.2);
}

.progress_custom .circle_custom.active .date {
    color: #0c95be;
}

/* Demande officielle */
.modalCreateOrEditSubject .hidden, #create_view .hidden {
    display: none !important;
}

.modalCreateOrEditSubject .officialRequestMeeting, #create_view .officialRequestMeeting, .modalCreateOrEditSubject .opinionRequestReason, #create_view .opinionRequestReason {
    background-color: #b6b6b612;
    margin-bottom: 1em;
}

.spinner-border.loading-fields {
    width: 6rem;
    height: 6rem;
    color: var(--btn-color-two);
}

#chat_reunion #messages {
    background-color: var(--main-body-color);
    border-radius: 5px; 
    height: 45vh; 
    padding: 1em; 
    overflow-y: scroll; 
    scrollbar-color: #a9dc76 #333333;
}

#chat_reunion #connected_participants {
    background-color: #323232; 
    color: white; 
    padding: 1em; 
    height: 45vh;
}

ul.dropdownMeetings {
    height: 15em;
    overflow-y: auto;
}

.rounded-1 {
    border-radius: 1rem !important;
}

.modalShowDocument {
    max-width: 80% !important; 
    margin: 30px auto !important;
}

.modalShowDocument .modal-body {
    padding: 0;
    min-height:400px; 
    background:#ccc;
}

.modalShowDocument .modal-body .close {
    position:absolute; 
    right:-30px; 
    font-size:2rem; 
    font-weight: normal; 
    color:#fff; 
    opacity:1;
}

.modalShowDocument .modal-body img {
    min-height:200px;
}

.objectLinkOfficialRequest {
    min-height:50%; 
    width:100%
}

.commentsContainer {
    padding: 3rem 10rem !important;
    border-radius: .25rem !important;
}

.comment-userimg {
    color: black; 
    margin-left: .1rem;
}

.commentNameSubject {
    margin-left: .5rem !important;
    margin-right: .25rem !important;
    font-weight: bold;
    color: black;
}

.commentDateSubject {
    margin-top: .25rem !important;
    font-size: .5rem; 
    color: black;
}

.commentTextSubject {
    margin-left: .25rem !important;
    color: black;
}

.reply_btn {
    border-radius: 1rem !important; 
    background: var(--btn-color-active) !important;
    border-radius: .5rem !important;
    font-size: .5rem !important;
}

.replyForCommentSubject {
    margin-bottom: .25rem !important;
    border-left: 2px solid black; 
    margin-left: 1rem; 
    padding-left: 3rem;
}

.replyDateSubject {
    margin-top: .25rem !important;
    font-size: .5rem; 
    color: black;
}

.testfoot {
    margin-left: 3rem !important;
    font-size: .5rem;
}

.submit-reply {
    border-radius: 3rem !important; 
    background: var(--btn-color-active) !important;
    margin: .5rem !important;
}

.backToPreviousPage {
    margin-right: 1.5rem !important;
    margin-left: auto !important;
    color: #fff !important;
    height: fit-content;
}

.viewCreateBody {
    border: 4px solid var(--main-body-color); 
    border-bottom: none; 
    border-top: none;
    padding: 1.5rem !important;
}

.choices-border {
    border: 4px solid white; 
    border-bottom: none; 
    border-top: none;
    padding: 1.5rem !important;
}

.choices-hline {
    border: 2px solid white; 
}

.dropdown-menu-subject-answer {
    transform: translate3d(-267px, 29px, 0px) !important;
    cursor: pointer;
}

.voteDetailsBlock {
    border: 2px solid var(--primary-color-four);
    border-radius: .5rem;
    padding: 1em 1em 0em 1em;
    background-color: #b6b6b612;
    margin-bottom: 1em;
    display: none;
}

#notification-nav> .dropdown-menu {
    height: 300px;
    width: 250px;
    overflow-y: scroll;
}

#notification-nav> .dropdown-menu .toast-header {
    background-color: #203335;
    border-radius: 0;
    color: white;
}

.toast-header {
    background-color: #4A6D5E;
    color: white;
}

/* #notification-nav> .dropdown-menu .dropdown-item:hover {
    border: 2px solid rgb(32, 51, 53);
} */

#notification-nav>div::-webkit-scrollbar {
    width: 5px;
}

#notification-nav>div::-webkit-scrollbar-track {
    background: #f1f1f1;
}

#notification-nav>div::-webkit-scrollbar-thumb {
    background: #888;
}

#notification-nav>div::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.inputSearch {
    background: transparent;
    border-bottom: 1px solid #dee2e6;
    border-top: none;
    border-right: none;
    border-left: none;
    color: #ffffff;
    width: 7.5em;
    margin-right: -1px;
}

.inputSearch:focus {
    outline-width: 0;
}

.adminSettingsLink {
    color: #ffffff;
    padding: 0.34em;
    font-size: 1.5em !important;
}

.adminSettingsLink:hover {
    color: #ffffff;
}

.nav-link-btn {
    font-weight: bold;
    text-decoration: none;
    color: #ffffff;
    background-color: var(--btn-color-two);
    border: 1px solid black;
    border-radius: 5px;
    margin: 0rem 1rem;
    padding-right: .5rem;
    padding-left: .5rem;
    padding: 0.5rem 0.5rem;
}

.nav-link-btn:hover {
    background-color: var(--btn-color-active);
}

.nav-link-btn.active {
    background-color: var(--btn-color-active);
    color: black !important;
}

.cse-link {
    border: none;
    background: none;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
}

.cse-link:hover {
    color: var(--btn-color-active);
}

#redirect-flash-loading {
    background: rgba(0,0,0,0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index :999;
}

#redirect-div {
    position: relative;
    top :45vh;
}

.flash-loading {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #34db87;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin-inline: auto;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

#mainboard
{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-template-areas:    "information"
                            "mandates"
                            "commissions"
                            "subjects" 
                            "summary";
}

.section-information {
    /* grid-column: 1 */
      grid-area: information;
}
.section-mandates {
    /* grid-column: 1 */
      grid-area: mandates;
}
.section-commissions {
    /* grid-column: 1 */
      grid-area: commissions;
}
.section-subjects {
    /* grid-column: 1 */
      grid-area: subjects;
}
.section-summary {
    /* grid-column: 2 */
      grid-area: summary;
}
/* .section-calendar {
    grid-column: 2;
    grid-area: calendar;
} */

#subjectMain {
    margin: 2em 10em;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-template-areas:    "progress"
                            "information"
                            "documents"
                            "officialrequest" 
                            "comments"
                            "exchange";
    /* grid-row-gap: 3em; */
}

.progressBarSubject {
    grid-area: progress;
}

.informationSubject {
    grid-area: information;
    padding-top: 2em;
    margin-top: 2em;
    border-top: 2px solid var(--main-body-color);
    
}

.documentAttachementSubject {
    grid-area: documents;
    padding-top: 2em;
    margin-top: 2em;
    border-top: 2px solid var(--main-body-color);
    
}

.officialRequestSubject {
    grid-area: officialrequest;
    padding-top: 2em;
    margin-top: 2em;
    border-top: 2px solid var(--main-body-color);
    
}

.commentsSubject {
    grid-area: comments;
    padding-top: 2em;
    margin-top: 2em;
    border-top: 2px solid var(--main-body-color);
    
}
.commentExchangeSubject {
    grid-area: exchange;
    padding-top: 2em;
    margin-top: 2em;
    border-top: 2px solid var(--main-body-color);
}    

.meetingSubjectCommentsSection {
    margin-top: 0.6em; 
    border: 1px solid #ccc;
}

.commentDetails {
    width: 92.5%; 
    float: left; 
    margin-bottom: 0px;
}

.commentDate {
    font-style: italic; 
    font-size: 0.8em;
}

.meetingSubjectComment {
    background-color: #c3c4c4;
}

.replyComment {
    margin:0 12.5%; 
    padding-bottom: 1em;
}

@media screen and (min-width: 768px)
{
    #mainboard
    {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, auto);
        grid-template-areas:    "information summary"
                                "mandates summary"
                                "commissions summary"
                                "subjects summary";
    }
}

/* Absolute Center CSS Spinner */
#container-custom .loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
  
/* Transparent Overlay */
#container-custom .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
}
  
/* :not(:required) hides these rules from IE9 and below */
#container-custom .loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}
  
#container-custom .loading:not(:required):after {
    content: '';
    display: block;
    font-size: 40px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 2500ms infinite linear;
    -moz-animation: spinner 2500ms infinite linear;
    -ms-animation: spinner 2500ms infinite linear;
    -o-animation: spinner 2500ms infinite linear;
    animation: spinner 2500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}
  
  /* Animation */
  
@-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@-moz-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@-o-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}

#affectationForSubject {
    max-height: 90%;
}

#container-custom .list-group-item-custom {
    border: 2px solid #dee2e6  !important;
}

#container-custom .list-group-item-custom:hover {
    background-color: var(--btn-color-active);
}

#container-custom #affectationForSubject .text-custom {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
}

#meetingsOfTheDay, #summaryMeetingTab, #summarySubjectTab {
    padding-left: 1em;
}

#summaryMeetingTab .list-group-item-custom {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border: 2px solid white !important;
}

#container-custom .list-group-item-custom:hover {
    background-color: #e9ecef !important;
    border: 2px solid var(--btn-color-two) !important;
}

#container-custom #paginationForSubject,
#container-custom #paginationForMeeting {
    font-size: 0.75em;
    font-weight: bold;
}

#container-custom #paginationForSubject .pagination li,
#container-custom #paginationForMeeting .pagination li {
    background-color: white !important;
}

#container-custom #paginationForSubject .pagination li:hover,
#container-custom #paginationForMeeting .pagination li:hover {
    background-color: var(--btn-color-active) !important;
    color: black;
}

#container-custom #paginationForSubject .pagination li button,
#container-custom #paginationForMeeting .pagination li button {
    background-color: unset !important;
    border: 1px solid black;
    padding: 0 0.25em !important;
    font-weight: bold;
}

#container-custom #paginationForSubject .pagination .paginationActive:hover,
#container-custom #paginationForMeeting .pagination .paginationActive:hover {
    background-color: var(--main-body-color) !important;
    color: white !important;
}

#container-custom #paginationForSubject .pagination .paginationActive,
#container-custom #paginationForMeeting .pagination .paginationActive {
    background-color: var(--main-body-color) !important;
}

#container-custom #paginationForSubject .pagination .paginationActive span,
#container-custom #paginationForMeeting .pagination .paginationActive span {
    color: white !important;
}


ul.list-group-hide:hover > li.list-group-item-action-custom:hover {
    background: white;
    border: 2px solid black;
    color: black
}

li.list-group-item-action-custom:hover {
    background: var(--btn-color-active);
    color: white;
}

li.list-group-item-action-custom, .list-group-item-action-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    font-weight: bold;
    border: 2px solid black;
    border-radius: 5px;
    margin: 5px 0;
    height: 2.5rem;
    text-decoration: none !important;
    color: black
}

.list-group-item-action-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    font-weight: bold;
    border: 2px solid black;
    border-radius: 5px;
    margin: 5px 0;
    height: 5rem;
    text-decoration: none !important;
    /* transform: scale(1); */
    transition: all .3s ease;
    color: black
}

.list-group-item-action-custom:hover {
    color: white;
    background: var(--btn-color-active);
    transition: all .3s ease;
}

.list-group-item-action-custom:hover + ul > .list-group-item-action-custom {
    color: white;
    background: var(--btn-color-active);
    transition: all .3s ease;
}

.actionSection {
    display: block;
    margin: 1rem;
    padding: 0.5rem;
    position: relative;
    border: 2px solid var(--main-body-color);
    border-radius: 0.6rem;    
}

.inputGroup {
    background-color: #fff;
    display: block;
    margin: 0.2rem 0;
    position: relative;
    border: 2px solid rgba(60, 64, 67, 0.15);
    border-radius: 0.6rem;
}

.inputGroup input {
    width: 4rem;
    height: 3rem;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
}

.inputGroup label {
    border-radius: 0.5rem;
    border: 2px solid white;
    padding: 1rem 2rem;
    margin: 0;
    width: 100%;
    display: block;
    text-align: left;
    color: #3C454C;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
}

.inputGroup label:before {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    content: '';
    background-color: var(--btn-color-active);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
    opacity: 0;
    z-index: -1;
}

.inputGroup label:after {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    justify-content: center;
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900; 
    /* content: "\f00c"; */
    content: "\f00d";
    background-color: #fff;
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
    border: 2px solid #D1D7DC;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 1.5rem;
    z-index: 2;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 200ms ease-in;
}

.inputGroup label:hover:after {
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900; 
    content: "\f00c";
    background-color: var(--main-body-color);
    border-color: var(--main-body-color);
    color: #fff;
}

.inputGroup label:hover {
    border: 2px solid var(--btn-color-active);
    border-radius: 0.5rem;
    color: #3C454C;
}

.inputGroup input:checked ~ label {
    color: #fff;
}

.inputGroup input:checked ~ label:before {
    transform: translate(-50%, -50%) scale3d(56, 56, 1);
    opacity: 1;
}

.inputGroup input:checked ~ label:after {
    /* background-color: #54E0C7; */
    /* border-color: #54E0C7; */
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900; 
    content: "\f00c";
    background-color: var(--main-body-color);
    border-color: var(--main-body-color);
}

.inputGroup input:checked ~ label:hover {
    border-color: crimson;
}

.inputGroup input:checked ~ label:hover:after {
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900; 
    content: "\f00d";
    background-color: crimson;
    border-color: crimson;
}

/* Heures de délégation */
div.delegationHourAvailable {
    margin-left: 18%;
    display: flex;
    flex-direction: column;
    width: fit-content;
}

div.delegationHourAvailable > span {
    border: 2px solid;
    width: fit-content;
    padding: 0.1em 0.7em 0.1em 0.6em;
    margin: auto;
    border-image: linear-gradient(45deg, var(--main-body-color), var(--btn-color-active)) 1;
    font-weight: bold;
    -webkit-box-shadow: -4px 4px 7px 1px rgba(86,86,86,0.31); 
    box-shadow: -4px 4px 7px 1px rgba(86,86,86,0.31);
}

span.hourCounter {
    border: 2px solid;
    padding: 0.1em 0.7em 0.1em 0.6em;
    border-image: linear-gradient(45deg, var(--main-body-color), var(--btn-color-active)) 1;
    font-weight: bold;
    -webkit-box-shadow: -4px 4px 7px 1px rgba(86,86,86,0.31); 
    box-shadow: -4px 4px 7px 1px rgba(86,86,86,0.31);
}

.small-under-input {
    margin-top: -1em;
    margin-bottom: 1.5em;
}